$(document).ready(function() {
    var controller = new ScrollMagic.Controller();
    // detect if mobile browser. regex -> http://detectmobilebrowsers.com
    var isMobile = ($(window).width() <= 740);
    var isSmall = ($(window).width() <= 1200);
    var isTablet = ($(window).width() <= 800);


    var revealHomepage = function() {
      var revealTimeline = gsap.timeline({repeat: 0, yoyo: false});
      revealTimeline.fromTo(".homepage-pretitle",
        {opacity: 0},
        {opacity: 1, duration: .1, ease: "power2.in"},
        0
      );
      revealTimeline.fromTo(".homepage-title",
        {opacity: 0},
        {opacity: 1, duration: .1, ease: "power2.in"},
        "<"
      );
      revealTimeline.fromTo("#header", 
      {opacity: 0, y:-200},
        {opacity: 1, y:0, duration: .4, ease: "power2.in"},
        ">"
      );
      revealTimeline.fromTo(".homepage-list-container",
        {opacity: 0, y:-40},
        {opacity: 1, y:0, duration: .4, ease: "power2.in"},
        ">"
      );
      revealTimeline.fromTo(".homepage-form-container",
        {opacity: 0, y:40},
        {opacity: 1, y:0, duration: .4, ease: "power2.in"},
        "<"
      );
      revealTimeline.fromTo(['.nav-mountain'], 
        {xPercent:2, opacity: 1},
        {xPercent:0, duration: .4, ease: "power2.in"},
        "<"
      );
      revealTimeline.fromTo(['.fire-mountain'], 
        {xPercent:-2, opacity: 1},
        {xPercent:0, duration: .4, ease: "power2.in"},
        "<"
      );
    }

    var slideOutDrawings = function() {
      var drawingsTween = new TimelineMax()
      .to('.fire-mountain', 1, {yPercent: 20})
      .to('.nav-mountain', 1, {yPercent: 20}, 0);

      var drawingsScene = new ScrollMagic.Scene({
        triggerElement: '#header',
        triggerHook: 0,
        offset: 20,
        duration: 800,
      }).setTween(drawingsTween)
      .addTo(controller);
    }
    
    slideOutDrawings();
    revealHomepage();
    
    if (!isTablet) {
        fitty(".homepage-title");
    }
});